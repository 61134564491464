.game-card-wrapper {
    max-width: 300px;
    width: 100%;
    height: 180px;
    max-height: 100%;
    background-color: white;
    margin: 10px auto;
    border-radius: 10px;
    p {
        color: black;
        font-size: 20px;
    }
    .game-card-logo {
        display: block;
        margin: auto auto;
        max-width: 250px;
        width: 90%;
        height: 100%;
        padding: 15px 0 15px 0;
        // padding: auto;
        
    }
}