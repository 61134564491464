@import 'variables';


.nav-button {
  color: black;
}

.page-header {
  text-align: center;
}

.page-content-container {
  color: white;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: rgba(0,0,0, 0.7);
  border-radius: 9px;
}

.question-block {
  margin: 50px 0;
  &__header {
    text-align: center;
  }
  &__body {
    font-size: 19px;
    font-weight: 600;
    &__click-span {
      color: #FFADAD;
    }
    &__italics-bold {
      font-weight: 750;
      font-style: italic;
    }

  }

}