@import 'variables';

body {
  background-color: white;
}

.landing-container {
  color: $white;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: rgba(0,0,0, 0.7);
  border-radius: 9px;
  text-align: center;
  .link-to-events {
    color: #FFADAD;
    font-size: 19px;
    font-weight: 600;
    text-decoration: underline;
  }
  .monthly-logo {
    width: 100%;
  }
}

.landing-button {
  background-color: $white;
  color: #000;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin: 0 20px 20px;
  border-radius: 9px;
  
  // margin-top: 70px;
  @media (min-width: $breakpoint-mobile) {
    padding-left: 3.5vw;
    padding-right: 3.5vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
  }
  @media (min-width: $breakpoint-xl) {
    padding-left: 55px;
    padding-right: 55px;

  }
  a {
    color: $black;
  }
}

.landing-text {
  font-size: 20px;
  font-family: $main-typeface;
  display: inline-block;
  max-width: 600px;
  align-content: center;

  &__bigger {
    font-size: 22px;
    font-weight: 670;
  }

}



.landing-header {
  text-align: center;
  font-size: 30px;
  font-family: $main-typeface;
  display: inline-block;
  // max-width: 600px;
  width: 100%;
  
}

.bold {
  font-weight: bold;
}

button {
  border: none !important;
}

.landing-button:hover {

  background-color: #5865F2 !important;

  border: none;
}

.landing-button__discord:hover {

  background-color: #8C9EFF !important;

  border: none;
}

.landing-page-map {
  max-width: 500px;
  height: 300px;
  width: 80%;
}

