@import 'variables';

// body {
//   background-color: black;
// }
.link-to-events {
  color: #C10404;
}
.big-event-container {
  background-color: rgb(0, 0, 0);
  color: white;
  max-width: 100%;
  padding-top: 25px;
  z-index: 0;
  text-align: center;
  .big-event-content{
    position: relative;
    z-index: 4;
    .big-ol-logo {
      width: 100%;
      max-width: 790px;
    }
    .register-button-wrapper {
      // margin: 0 auto;
      .register-now {
        display: block;
        margin: 0 auto;
        background-color: #C10404;
        color: white;
        font-size: 20px;
        font-weight: 700;
        max-width: 200px;
        padding: 20px;
        border-radius: 8px;
        border-color: white;
        border-width: 2px;
        border-style: solid;
        text-decoration: none;
        // height: 100%;
        .register-text {
          margin-bottom: 0;
        }
      }
      .register-now:hover {
        background-color: pink;
        text-decoration: none;
      }
    }
    .big-event-info-block {
      font-size: 20px;
    }
    .game-cards-container {
      margin: 0 auto;
      max-width: 700px;
      display: flex;
      flex-wrap: wrap;
    }
    .covid-policy {
      font-size: 25px;
      background-color: rgba(75, 75,75,75);
      max-width: 350px;
      margin: 0 auto;
      border-radius: 5px;

    }


  }
  .logo-sub-description {
    p {
      margin-bottom: 0;
    }
    font-size: 30px;
    height: 100%;
    margin-bottom: 10px;
    .tournament-time {
      font-size: 25px;
    }
  }

  .mixfest-footer {
    .footer-logo {
      margin: auto;
      max-width: 200px;
    }
    position: relative;
    z-index: 4;
    height: 100px;
    width: 100%;
    background-color: black;

  }
}

.mixfest-container {
  background-color: rgb(0, 0, 0);
  color: white;
  max-width: 100%;
  padding-top: 25px;
  z-index: 0;
  text-align: center;
  .mixfest-content{
    position: relative;
    z-index: 4;
    .big-ol-logo {
      width: 100%;
      max-width: 790px;
    }
    .register-button-wrapper {
      // margin: 0 auto;
      .register-now {
        display: block;
        margin: 0 auto;
        background-color: #C10404;
        color: white;
        font-size: 20px;
        font-weight: 700;
        max-width: 200px;
        padding: 20px;
        border-radius: 8px;
        border-color: white;
        border-width: 2px;
        border-style: solid;
        text-decoration: none;
        // height: 100%;
        .register-text {
          margin-bottom: 0;
        }
      }
      .register-now:hover {
        background-color: pink;
        text-decoration: none;
      }
    }
    .mixfest-info-block {
      font-size: 20px;
    }
    .game-cards-container {
      margin: 0 auto;
      max-width: 700px;
      display: flex;
      flex-wrap: wrap;
    }
    .covid-policy {
      font-size: 25px;
      background-color: rgba(75, 75,75,75);
      max-width: 350px;
      margin: 0 auto;
      border-radius: 5px;

    }


  }
  .logo-sub-description {
    p {
      margin-bottom: 0;
    }
    font-size: 30px;
    height: 100%;
    margin-bottom: 10px;
    .tournament-time {
      font-size: 25px;
    }
  }

  .mixfest-footer {
    .footer-logo {
      margin: auto;
      max-width: 200px;
    }
    position: relative;
    z-index: 4;
    height: 100px;
    width: 100%;
    background-color: black;

  }
}



.snow-gif {
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
  position:fixed;
  top:0;
  left:0;
  opacity: 0.4;
}

.city-layer {
  background-position: center;
  z-index: 1;
  background-color: black;
  // width:2000px;
  width: 100%;
  // min-width: 800px;
  position: fixed;
  bottom:0;
  left:50%;
  margin-left: -50%;
  opacity: 0.5;
}

.blast-layer {
  background-position: center;
  z-index: 1;
  background-color: black;
  // width:2000px;
  width: 100%;
  min-width: 800px;
  position: fixed;
  top:0;
  left:50%;
  margin-left: -50%;
  opacity: 0.4;
}


@media (max-width: $breakpoint-mobile) {
  .city-layer {
  //   width:80%;
  width: 800px;
  //   min-width: 800px;
  //   position: fixed;
  //   bottom:0;
  //   left:50%;
  margin-left: -400px;
  //   opacity: 0.5;
  }
    .logo-normal {
      display: none;
  
    }
    .nav-buttons-container {
      display: flex;
      flex-wrap: wrap;
      & .nav-button {
        font-size: 15px;
      }
    }
  }

  .event-body {
    display: flex;
    @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        img {
            max-width: 50%;
            margin: auto;
          }
    }
    .event-body-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    img {
      width: 100%;
    }
  }