@import 'variables';

.pete-container {
    color: $white;
    margin: 20px auto 20px auto;
    max-width: 800px;
    padding: 20px;
    background-color: rgba(0,0,0, 0.7);
    border-radius: 9px;
    text-align: center;
  }