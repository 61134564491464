@import '../../views/variables';

.navigation-bar {
  z-index: 3;
  background-image: url('https://cmsw.mit.edu/wp/wp-content/uploads/2013/08/Fighting-Games.jpg');
  background-position: center;
  padding: 40px;
  position: relative;
}

.navbar-wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: rgba(255, 255, 255, 0.7);
  
}

.navbar-link {
  color: black;
}

.nav-margin-container {
  margin-bottom: 20px;
  // margin: 20px;
  background-color: white;
  text-align: center;
}

.navigation-text {
  color: #fff !important;
  //   Will need to investigate why the font wont change
  font-family: $main-typeface;
}

.navigation-text:hover {
  color: red !important;
}

.navbar-brand {
  font-size: 60px;
  font-family: $main-typeface;
  font-weight: 600;
  // margin-left: 15vw;
  filter: drop-shadow(0, 0, 4px, $black);
  margin: 0;
  // margin-top: 20px;
  // display: flex;
  // width: 100%;
  // align-content: center;
}

.logo-uppercase {
  color: #b40202;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
}

.logo-normal {
  color: black;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
}

.nav-buttons-container {
  width: 100%;
  max-width: 1000px;
  display:flex;
}

.nav-button {
  height: 40px;
  // padding: 0 30px;
  margin: 0 auto;
  // color: black;
  font-weight: 600;
  font-size: 20px;

}

.button-wrapper{
  margin: 0 auto;
}

.navbar-link {
  font-size: 18px;
}

.dropdown {
  color: #fff !important;
}


@media (max-width: $breakpoint-mobile) {
  .logo-normal {
    display: none;

  }
  .nav-buttons-container {
    display: flex;
    flex-wrap: wrap;
    & .nav-button {
      font-size: 15px;
    }
  }
}