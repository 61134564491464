@import '../../views/variables';

.footer {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $black;
}

.footer li a {
  color: #fff;
  text-align: center;
  font-size: 2rem;
  // margin: 1rem;
}

.footer li a:hover {
  color: red;
}

li {
  float: left;
  list-style-type: none;
}
