.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
      & h5 {
          border-bottom: 1px solid;
      }
      & button {
          width: 100px;
          height: 50px;
          background-color: rgba(50, 50, 50, 1);
          color: white;
      }
    position:fixed;
    background: white;
    width: 80%;
    max-width: 400px;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color: black;
    text-align: center;
    padding: 20px;
  }

  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }